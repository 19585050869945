.popup-container {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-checkbox {
  display: none;
}

.popup-content {
  margin-top: 50px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 550px;
  max-height: 80%;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  /* Enable scrolling when content overflows */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .addroute-form {
    padding: 20px;
    padding-top: 8px;
  }
  input[type='checkbox'] {
    display: none;
  }
  .checkbox-group {
    margin-bottom: 16px;
  }
  .checkbox-custom {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin-right: 10px;
    position: relative;
    &::before {
      content: '\2713';
      /* Checkmark character */
      color: #14539a;
      font-size: 12px;
      font-weight: 600;
      /* Checkmark color */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
  .input-checkbox:checked + .checkbox-custom::before {
    opacity: 1;
  }
  label:hover .checkbox-custom {
    background-color: #f0f0f0;
  }
  .cnt-of-heading-n-cross {
    padding: 11px 12px 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cross-icon {
      cursor: pointer;
      color: red;
      font-size: 17px;
    }
    h2 {
      color: #000;
      font-weight: 450;
      display: flex;
      align-items: center;
      font-size: 19px;
      .route-icon {
        margin-right: 10px;
        color: black;
        font-size: 17px;
      }
    }
  }
  .custom-select {
    position: relative;
    display: block;
    max-width: 100%;
    /* Adjust as needed */
    select {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: white;
      cursor: pointer;
    }
  }
  .checkbox-list {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    flex-wrap: wrap;
    .week-day {
      font-size: 12px;
      color: black;
      font-weight: 350;
    }
    label {
      flex-basis: 20%;
      margin-right: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  label {
    margin-top: 13px;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
    color: #666666;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .input-text,
  .input-number,
  .textarea,
  .select,
  .input-time,
  .input-datetime-local {
    font-size: 13px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  select[multiple] {
    height: 100px;
    /* Adjust the height based on your design */
  }
  .button-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {
      padding: 6px 29px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
    }
    button[type='submit'] {
      background-color: #14539a;
      color: white;
    }
    button[type='submit']:hover {
      background-color: #265fa0;
    }
    button[type='button'] {
      background-color: #ccc;
      color: #333;
    }
  }
}

// responsive styles are given below
@media (max-width: 768px) {
  /* Media query for smaller screens */
  .cnt-of-heading-n-cross {
    padding: 12px 8px;
    .cross-icon {
      font-size: 15px;
    }
    h2 {
      font-size: 16px;
      .route-icon {
        margin-left: 8px;
        font-size: 15px;
      }
    }
  }
  .custom-select {
    select {
      padding: 8px;
    }
  }
  .checkbox-list {
    label {
      margin-right: 10px;
    }
  }
  .input-text,
  .input-number,
  .textarea,
  .select,
  .input-time,
  .input-datetime-local {
    font-size: 12px;
    padding: 8px;
  }
  .button-group {
    button {
      padding: 6px 12px;
      font-size: 12px;
      margin-left: 6px;
    }
  }
}

@media (max-width: 480px) {
  /* Further adjustments for smaller screens */
  .popup-content {
    margin-left: 8px;
    margin-right: 8px;
  }
  .addroute-form {
    padding: 10px !important;
    padding-top: 15px;
  }
  .cnt-of-heading-n-cross {
    padding: 8px;
    h2 {
      font-size: 15px;
      .route-icon {
        margin-left: 6px;
        font-size: 14px;
      }
    }
  }
  .custom-select {
    select {
      padding: 6px;
    }
  }
  .checkbox-list {
    label {
      margin-right: 6px;
    }
  }
  .input-text,
  .input-number,
  .textarea,
  .select,
  .input-time,
  .input-datetime-local {
    font-size: 14px;
    padding: 6px;
  }
  .button-group {
    button {
      padding: 4px 10px;
      font-size: 11px;
      margin-left: 4px;
    }
  }
}

.highlighted-text {
  margin: 9px 0;
  display: flex;
  gap: 5px;
  align-items: center;
}

.selected-place {
  position: relative;
  font-size: 14px;
  padding: 3px 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  padding-right: 7px;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
    &::before {
      opacity: 1;
    }
  }
}

// custom select dude
.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-family: 'Open Sans', 'Helvetica Neue', 'Segoe UI', 'Calibri', 'Arial',
    sans-serif;
  font-size: 14px;
  color: #60666d;
  &__current {
    max-height: 43px;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 0.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    width: 100%;
    padding: 6px;
    cursor: pointer;
    outline: none;
    &:focus {
      & + .select-box__list {
        opacity: 1;
        animation-name: none;
        .select-box__option {
          cursor: pointer;
        }
      }
      .select-box__icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    font-size: 14px;
    transform: translateY(-50%);
    transition: 0.2s ease;
  }
  &__value {
    display: flex;
  }
  &__input {
    display: none;
    &:checked + .select-box__input-text {
      display: block;
    }
  }
  &__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 6px;
    background-color: #fff;
  }
  &__list {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    z-index: 9;
    position: absolute;
    width: 100%;
    padding: 0;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
  }
  &__option {
    display: block;
    padding: 7px;
    background-color: #fff;
    &:hover,
    &:focus {
      color: #546c84;
      background-color: #fbfbfb;
    }
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
