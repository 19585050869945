.loader {
  border: 5px solid #ddd;
  border-top: 5px solid #14539a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
