.slides-cnt {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 280px) {
    padding: 10px;
  }
}

.slide-box {
  max-width: 700px;
  width: 90%;
  opacity: 1;
  height: auto;
  padding: 32px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: white;
  @media (max-width: 480px) {
    padding: 12px;
  }
  .slide-info {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 550;
    @media (max-width: 480px) {
      font-size: 0.7rem;
    }
  }
}

.cancel-btn {
  text-decoration: underline;
  color: #626d75;
  font-weight: 450;
  @media (max-width: 280px) {
    font-size: 0.7rem;
  }
}

.button-primary {
  @media (max-width: 280px) {
    font-size: 0.7rem;
  }
}
