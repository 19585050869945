.big-schedule-cnt {
  padding-top: 120px;
  margin: 0 auto;
  .map-icon-cnt {
    padding: 0 32px;
    margin: 0 auto;
    margin-bottom: 1rem !important;
    display: flex;
    align-items: center;
    .path-icon {
      width: 50px;
    }
    .route-name {
      font-weight: 550;
    }
  }
}

.pdf-btn {
  color: rgb(20, 83, 154) !important;
  cursor: pointer;
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
}

.edit {
  margin-top: 2px;
}

.schedule-cnt {
  width: 100%;
  padding: 0 22px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    padding: 0 12px;
  }
  .filters-section-mobile {
    left: 0;
    z-index: 9999999;
    padding: 22px;
    position: absolute;
    top: 60px;
    height: 100vh !important;
    width: 100vw;
    background: white;
    display: block;
    .header-of-filter {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding-bottom: 8px;
    }
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      width: 100%;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 11px 14px;
      @media (max-width: 480px) {
        padding: 14px;
      }
    }
    .apply-filter-btn {
      height: 37px;
      width: 100%;
      background-color: rgba(38, 102, 177);
      border: none;
      border-radius: 20px;
      color: white;
      padding: 5px 10px;
      font-size: 13px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: darken(rgba(38, 102, 177), 10%);
      }
    }
  }
  .filters-section {
    @media (max-width: 480px) {
      display: none;
    }
    height: fit-content;
    flex-basis: 30%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px;
    border-radius: 0.5rem;
    .header-of-filter {
      display: flex;
      align-items: center;
      font-size: 16px;
      padding-bottom: 8px;
    }
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      width: 100%;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 11px 14px;
    }
    .apply-filter-btn {
      height: 37px;
      width: 100%;
      background-color: rgba(38, 102, 177);
      border: none;
      border-radius: 20px;
      color: white;
      padding: 5px 10px;
      font-size: 13px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: darken(rgba(38, 102, 177), 10%);
      }
    }
  }
  .filter-icon {
    z-index: 8;
    position: fixed;
    bottom: 18px;
    right: 18px;
    font-weight: 900;
    font-size: 19.5px;
    display: none;
    align-items: center;
    justify-content: center;
    color: white;
    width: 40px;
    height: 40px;
    background: var(--main-color);
    border-radius: 70px;
    @media (max-width: 480px) {
      display: flex !important;
    }
  }
  .schedule-based-on-order {
    @media (max-width: 480px) {
      flex-basis: 100%;
      .edit {
        font-size: 14px;
        margin-top: 3px;
      }
    }
    flex-basis: 65%;
    padding: 0 0 22px;
    .each-schedule {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 16px;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.02);
      }
      .product-card {
        @media (max-width: 480px) {
          width: 100%;
        }
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 48%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 10px;
        transition: transform 0.3s ease;
        margin-top: 1rem;
        .product-image {
          width: 100px;
          height: 100px;
          object-fit: contain;
          border-radius: 4px;
          margin-right: 16px;
          @media (max-width: 480px) {
            width: 70px;
            height: 70px;
          }
        }
        .product-details {
          flex-grow: 1;
          .product-name {
            font-size: 15px;
            margin-bottom: 8px;
            color: #333;
            @media (max-width: 480px) {
              font-size: 13px;
            }
          }
          .product-quantity {
            font-size: 13px;
            color: #666;
            @media (max-width: 480px) {
              font-size: 11.5px;
            }
          }
        }
      }
      p {
        margin-bottom: 8px !important;
        @media (max-width: 480px) {
          margin-bottom: 6px !important;
        }
        span {
          font-size: 15px;
          color: #6e6969;
          font-weight: 400;
          @media (max-width: 480px) {
            font-size: 12px;
          }
        }
      }
      .left-schedule-info,
      .right-schedule-info,
      .middle-schedule-info {
        p {
          margin: 0;
          font-size: 14px;
          color: #333;
          @media (max-width: 480px) {
            font-size: 12px;
          }
        }
      }
      .left-schedule-info,
      .right-schedule-info {
        flex-basis: 40%;
        @media (max-width: 480px) {
          flex-basis: 46%;
        }
      }
      .middle-schedule-info {
        flex-basis: 100%;
        justify-content: space-between;
        display: flex;
        @media (max-width: 480px) {
          margin-bottom: 10px;
        }
        .status {
          @media (max-width: 480px) {
            padding: 1px 8px;
            font-size: 12px;
          }
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          padding: 0.5px 1.5rem;
          border-radius: 2rem;
          color: white;
          background: #14539a;
          font-weight: 550;
        }
      }
      .right-schedule-info {
        text-align: right;
      }
      .schedule-date {
        font-size: 15.5px;
        color: #606465;
        margin: 0;
        font-weight: 650;
      }
    }
    .total-products {
      a {
        font-size: 14px;
        color: #14539a;
        text-decoration: none;
        transition: color 0.3s ease;
        &:hover {
          color: #0d3472;
        }
        @media (max-width: 480px) {
          font-size: 12.5px;
        }
      }
    }
    .see-products {
      cursor: pointer;
      @media (max-width: 480px) {
        font-size: 12px;
      }
      color: #14539a;
      font-size: 14px;
      flex-basis: 100%;
      text-align: center;
      text-decoration: none;
      /* Remove underlines from links */
      transition: color 0.3s ease;
      /* Add a color transition on hover */
      &:hover {
        color: #0d3472;
        /* Change link color on hover */
      }
      .arrow-down {
        display: inline;
      }
    }
  }
}
