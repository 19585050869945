.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 800px;
  height: 200px;
  border-radius: 4px;
  background-color: white;
  h1 {
    color: #14539a;
    font-weight: 700;
  }
}

.add-product-btn {
  position: fixed !important;
  bottom: 40px !important;
  right: 30px !important;
  font-size: 16px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  .plus {
    font-size: 2rem !important;
    margin-right: 0.6rem;
    @media (max-width: 480px) {
      font-size: 1rem !important;
    }
  }
  @media (max-width: 480px) {
    height: 42px !important;
    width: 120px !important;
    font-size: 0.8rem !important;
  }
}

.last-range-cnt {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  padding-left: 1rem;
  .last-range-input {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  @media (max-width: 480px) {
    .add-range-btn {
      flex-basis: 50%;
      margin: 0 !important;
      height: 36.6px !important;
      font-size: 14px !important;
    }
  }
}

.range-cnt {
  padding-left: 1rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  .delete {
    align-self: end;
    font-size: 1.7rem;
    color: red;
    cursor: pointer;
  }
  .from {
    flex-basis: 25%;
  }
  .to {
    flex-basis: 25%;
  }
  .price {
    flex-basis: 45%;
  }
  @media (max-width: 480px) {
    .from {
      flex-basis: 48%;
    }
    .to {
      flex-basis: 48%;
    }
    .price {
      flex-basis: 100%;
    }
    .delete {
      font-size: 1.2rem;
    }
  }
}

.header-add-range-of-quantity {
  font-size: 15px;
}

@media (max-width: 480px) {
  .error {
    font-size: 11px;
  }
}

.search-bar-component {
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  .search-bar {
    display: flex;
    width: 45%;
    position: relative;
    height: 50px;
    align-items: center;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    @media (max-width: 480px) {
      width: 100%;
      height: 45px;
      font-size: 13px;
    }
    input[type='text'] {
      border: none;
      padding-left: 12px;
      flex-grow: 1;
      border-radius: 4px;
      outline: none;
    }
    .search-icon {
      color: #0073e6;
      background: none;
      border: none;
      cursor: pointer;
      padding: 10px;
      font-size: 18px;
      @media (max-width: 480px) {
        font-size: 15px;
      }
    }
    .product-list {
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 0px 8px;
      position: absolute;
      z-index: 99999;
      width: 100%;
      position: absolute;
      top: 100%;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
      border: 1px solid #ddd;
      border-radius: 7px;
      margin-top: 9px;
      background: white;
      .each-product {
        margin: 0.5rem;
        padding: 0.6rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dcdcdc;
        .product-img {
          margin-right: 1rem;
          height: 70px;
          width: 70px;
          border: 1px solid #ddd;
          border-radius: 50%;
          object-fit: contain;
        }
        .mrp {
          color: #64676b;
          font-size: 14px;
        }
        .product-name {
          color: #14539a;
          font-weight: 500;
        }
      }
      .each-product:hover {
        cursor: pointer;
        background-color: #f3f3f2;
        border-radius: 7px;
      }
    }
  }
}

.mark-it-new-checkbox-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  input[type='checkbox'] {
    margin-right: 0.5rem;
  }
  .mark-it-all-new {
    color: #4f4d4de0;
    font-size: 15px;
    @media (max-width: 480px) {
      font-size: 13px;
    }
  }
  .new-icon {
    color: red;
    font-size: 33px;
    display: inline;
  }
}

.product-list-cnt {
  width: 100%;
  margin: 0 auto;
  padding: 100px 22px 22px;
  // background: white;
  @media only screen and (max-width: 383px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.search-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  .search-bar {
    width: 450px;
    padding: 8px;
    border-radius: 0.3rem;
    border: 1px solid #dee6f0;
    outline: none;
  }
}

.table {
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 383px) {
    font-size: 12px;
  }
  width: 100%;
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;
  .table-header {
    display: flex;
    background-color: #f2f2f2;
    font-weight: bold;
  }
  .product-name {
    font-weight: 600;
    color: #14539a !important;
    @media (max-width: 480px) {
      display: block !important;
      font-size: 11px !important;
    }
  }
}

.table-cell {
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-right: px solid #ddd;
  &:last-child {
    border-right: none;
  }
}

// for product form
.sell-modal {
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  height: 100%;
  overflow: scroll;
  justify-content: center;
  align-items: center;
}

.sell-modal-content {
  position: relative;
  background: #fff;
  width: 50vw;
  padding: 20px;
  overflow: scroll;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  input[type='checkbox'] {
    display: inline-block;
  }
  @media (max-width: 480px) {
    padding: 13px;
  }
}

.sell-modal-content h2 {
  font-size: 15px;
  color: #14539a;
}

.sell-modal-content .sell-modal-form {
  border-bottom: 0.5px solid #e5e7eb;
  display: flex;
  margin-top: 0.8rem;
  flex-direction: column;
  align-items: flex-start;
}

.sell-modal-content .sell-modal-form label {
  margin-bottom: 10px;
}

.sell-modal-content .sell-modal-form .checkbox-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sell-modal-content .sell-modal-form .checkbox-group label {
  display: flex;
  // color: #666666;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 410;
}

.sell-modal-content
  .sell-modal-form
  .checkbox-group
  label
  input[type='checkbox'] {
  margin-right: 10px;
  height: 18px;
  width: 18px;
  border-radius: 5px;
  border: 2px solid #ccc;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.sell-modal-content
  .sell-modal-form
  .checkbox-group
  label
  input[type='checkbox']:checked {
  background: #f0f0f0;
  border-color: #aaa;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.input-container h1 {
  font-size: 18px;
  margin-left: -0.5rem;
  margin-top: 1.5rem;
  font-weight: 500;
  color: #14539a;
  margin-bottom: 0.7rem;
}

.input-container label {
  font-size: 14px;
  font-weight: 450;
  margin-bottom: 10px;
}

.input-container input {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  width: 100%;
}

.input-container input:focus {
  border-width: 2px;
  outline: none;
  border-color: #428ed9;
}

.info-button {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}

.info-button .icon {
  color: #333;
  font-size: 18px;
  margin-right: 5px;
}

.info-button span {
  background: rgb(222, 230, 240);
  color: #14539a;
  padding: 0.2rem;
  border-radius: 8px;
  font-size: 13.5px;
  margin-right: 4px;
}

.offer-form-container {
  padding-left: 2rem;
  padding-top: 1rem;
}

.offer-form-container h1 {
  font-size: 18px;
  margin-left: -1rem;
  margin-top: -0.8rem;
  font-weight: 500;
  color: #14539a;
}

.wrapper {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
}

header h2 {
  font-size: 24px;
  font-weight: 600;
}

header p {
  margin-top: 5px;
  font-size: 16px;
}

.field input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 19px;
  margin-left: 12px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='radio'] {
  margin-right: 0.5rem;
}

.form-control {
  width: 100%;
}

.form-control .form-label {
  line-height: 1rem;
  font-size: 0.9rem;
  color: black;
  margin-bottom: 10px;
  display: block;
}

.form-control .select-input {
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb;
  padding: 10px;
}

.btn-primary {
  background-color: #14539a;
  border-radius: 30px;
  margin-top: 15px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  height: 45.6px;
  width: 10.7rem;
  font-weight: 400;
  font-size: 15px;
  outline: none;
  outline: 0;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
}

/* Media Queries */

@media only screen and (max-width: 768px) {
  .sell-modal-content {
    width: 90vw;
  }
  .btn-primary {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .sell-modal-content {
    width: 96vw;
  }
}

.slide-down {
  animation: slide-down 0.8s ease-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0);
  }
}

// sttttttyless for product table
.box-cnt {
  display: flex;
  justify-content: space-around;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  overflow-x: auto;
  z-index: 1;
}

.box-cnt .left-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.left-arrow:hover,
.right-arrow:hover {
  transform: scale(1.1);
}

.box-cnt > div:last-child {
  margin-left: 10px;
  /* Adjust spacing between the icons */
}

.box-cnt svg:hover {
  fill: #14539a;
  /* Adjust the hover color of the icons */
}

.box {
  @media only screen and (max-width: 383px) {
    font-size: 12px;
    margin: 0.5rem !important;
  }
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin: 0.9rem;
  @media only screen and (max-width: 768px) {
    flex-basis: 45%;
  }
  @media only screen and (max-width: 480px) {
    flex-basis: 100% !important;
    margin-bottom: 1.8rem;
    padding: 10px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h1 {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 500;
      color: #14539a;
      span {
        margin-right: 4px;
        font-size: 15px;
      }
      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
    .edit-icon {
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .subtopic {
    overflow: auto;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: 0.5px solid #e5e7eb;
    .subheading {
      font-weight: 450;
      font-size: 18px;
      width: 100%;
      display: block;
      text-align: center;
      margin: 10px 0;
      @media (max-width: 480px) {
        font-size: 14px;
      }
    }
    p {
      color: #6c7b85;
      font-size: 15px;
      margin-bottom: 5px;
      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
    .quantity {
      word-break: break-all;
    }
  }
}

.price-range {
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  h3 {
    font-weight: 300;
    font-size: 14px;
    text-align: center;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th,
  td {
    text-align: center !important;
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  th {
    text-align: center;
    width: 50%;
    font-weight: 500;
  }
}

@media only screen and (max-width: 480px) {
  .box-cnt {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.option-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  font-size: 0.92rem;
  padding: 0.8rem;
  transition: all 0.2s ease-in-out 0s;
  background: rgb(222, 230, 240);
  color: #14539a;
  margin: 0 1rem;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
  flex-basis: 33%;
  @media (max-width: 480px) {
    margin: 1rem;
    margin-bottom: 1.5rem;
    font-size: 13px;
    padding: 0.5rem;
  }
}

.option-active,
.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  color: #14539a;
  margin: 0 1rem;
  margin-bottom: 1.5rem;
  margin-top: 0.9rem;
  font-size: 0.92rem;
  padding: 0.8rem;
  flex-basis: 33%;
  transition: all 0.2s ease-in-out 0s;
  color: rgb(222, 230, 240);
  background: #14539a;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  @media (max-width: 480px) {
    margin: 1rem;
    margin-bottom: 1.5rem;
    font-size: 13px;
    padding: 0.5rem;
  }
}

.disabled-button {
  background-color: #e0e0e0;
  /* Light gray */
  color: #888888;
  /* Dark gray */
  /* Additional styles for disabled button */
  cursor: not-allowed;
  opacity: 0.6;
}

// styles just check if it works or not
.product-list-cnt {
  border-radius: 8px;
  width: 90%;
  margin: 0 auto;
  padding: 120px 16px 16px;
  @media (max-width: 480px) {
    max-width: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 383px) {
    padding: 100px 8px 8px;
  }
}

.search-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  .search-bar {
    width: 100%;
    max-width: 450px;
    padding: 8px;
    border-radius: 0.3rem;
    border: 1px solid #dee6f0;
    outline: none;
    @media only screen and (max-width: 383px) {
      font-size: 12px;
    }
  }
}

.table {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  width: 100%;
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;
  border-collapse: collapse;
  .table-header {
    display: flex;
    background-color: #eef0f3;
    font-weight: bold;
  }
  .table-cell {
    color: #424141;
    flex: 1;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: 467px) {
      padding: 6px;
      font-size: 11px;
    }
  }
  .table-row {
    margin: 8px;
    display: flex;
    flex-direction: column;
    transition: background-color 0.2s ease-in-out;
    .see-product-detail,
    .hide-product-detail {
      color: #14539a;
      font-size: 14px;
      padding-bottom: 12px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
    .hide-product-details {
      color: red !important;
    }
    // &:hover {
    //     background-color: #f2f2f2;
    // }
  }
  @media only screen and (max-width: 467px) {
    font-size: 14px;
  }
}

.table::-webkit-scrollbar {
  display: none;
}

.go-back-btn,
.submit-btn {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 30px;
  padding: 0.6rem;
  transition: all 0.2s ease-in-out 0s;
  background: #14539a;
  color: #fff;
  @media only screen and (max-width: 383px) {
    font-size: 13px;
  }
}

.back-n-h1-cnt {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h1 {
    font-weight: bold;
    color: #14539a;
    @media only screen and (max-width: 383px) {
      font-size: 11px;
    }
  }
}
