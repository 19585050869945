.inactive {
  opacity: 0.5;
  pointer-events: none;
  filter: grayscale(100%);
}

.heading-of-route-page {
  color: rgb(20, 83, 154);
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin: 2rem 0;
}

@media (max-width: 480px) {
  .heading-of-route-page {
    font-size: 15px;
    margin: 1rem 0;
  }
}

.route-boxes-cnt {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 120px;
  @media (max-width: 480px) {
    padding: 10px;
    padding-top: 100px;
  }
  $bg-color: #14539a;
  .circle {
    cursor: pointer;
    position: fixed;
    bottom: 44px;
    right: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background-color: $bg-color;
    border-radius: 50%;
    .plus {
      position: absolute;
      font-size: 15px;
      color: white;
      font-weight: bold;
    }
  }
}

.middle-cnt {
  position: relative;
  flex-direction: column;
  flex-basis: 48%;
  margin: 0 1rem;
  display: flex;
  @media (max-width: 480px) {
    flex-basis: 100%;
    margin: 0rem;
    .make-active-btn {
      padding: 3px;
      font-size: 12px;
    }
  }
}

.route-box {
  margin-bottom: 2.5rem;
  flex-basis: 45%;
  padding: 12px;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  position: relative;
  @media (max-width: 480px) {
    flex-basis: 100%;
    padding: 8px;
  }
  .links {
    font-weight: 700;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.map-icon {
  height: 100px;
}

.route-details {
  text-align: center;
}

.route-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #0d366f;
  margin-bottom: 5px;
}

.route-description {
  font-size: 0.9rem;
  color: #888;
}

.edit-icon {
  font-size: 22px;
  position: absolute;
  top: 10px;
  right: 10px;
  filter: none !important;
  cursor: pointer;
  color: #14539a;
}

.route-schedule,
.route-coverage,
.route-start-time,
.route-end-time,
.route-start-date {
  font-size: 1rem;
  margin-top: 10px;
  color: #555;
  line-height: 1.5rem;
  .label {
    font-size: 15px;
    font-weight: 600;
  }
}

@media (max-width: 480px) {
  .route-schedule,
  .route-coverage,
  .route-start-time,
  .route-end-time,
  .route-start-date {
    font-size: 0.8rem;
    .label {
      font-size: 0.85rem;
    }
  }
}

.route-start-date {
  margin-bottom: 14px;
}

.route-box .view-schedule-and-view-buyer-link {
  cursor: pointer;
  display: flex;
  flex-basis: 33%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #14539a;
  font-size: 0.8rem;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  @media (max-width: 480px) {
    justify-content: flex-start;
    font-size: 12px;
  }
  .icon {
    font-weight: 800;
    margin-right: 0.4rem;
  }
}

.make-active-btn {
  display: inline-flex;
  padding: 6px;
  position: absolute;
  z-index: 9999;
  border-radius: 0.5rem;
  background-color: #f0f0f0;
  top: 39%;
  text-align: center;
  font-size: 15px;
  width: 100%;
  font-weight: 500;
  color: #14539a;
  justify-content: center;
}

.route-box a:hover {
  text-decoration: underline;
  color: #0d366f;
}

.route-notify-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 200;
  .box {
    opacity: 1;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text {
      color: #333;
      font-weight: 450;
      font-size: 15px;
    }
    .text-red {
      color:red
    }
    div {
      display: grid;
      row-gap: 20px;
      max-width: 300px;

      p {
        color: red;
        font-size: 10px;
        padding-bottom: 10px;
        padding-left: 5px;
      }

      .button-group {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        padding: 6px 29px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 10px;
        font-size: 14px;
        background-color: #14539a;
        color: white;
        button[type="button"] {
          background-color: #ccc;
          color: #333;
        }
      }
    }
  }
}
