.about-seller-section-cnt {
  margin-top: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 12px;
  border-radius: 8px;
  .map-tag {
    font-size: 17px;
    text-align: center;
    font-weight: 470;
    margin: 15px 0;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
  .gst-n-tag-cnt {
    display: flex;
    justify-content: space-between;
    .tag {
      font-size: 20px;
      font-weight: 530;
      margin-bottom: 12px;
      @media (max-width: 480px) {
        font-size: 16px;
      }
    }
    .gst {
      font-size: 13px;
      font-weight: 700;
      color: #14539a;
      @media (max-width: 480px) {
        font-size: 12px;
        margin-top: 0.3rem;
      }
    }
  }
  .about-description {
    color: #6c7b85;
  }
  .sub-heading {
    font-size: 14px;
    color: #666666;
    font-weight: 600;
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
  .data {
    font-size: 15px;
    color: #6c7b85;
    font-weight: 500;
    margin-left: 0.5rem;
    @media (max-width: 480px) {
      font-size: 13px;
    }
  }
  .icon {
    color: #14539a !important;
    display: inline;
    margin-right: 7px;
  }
  @media (max-width: 480px) {
    .type-cnt {
      margin: 0.4rem 0;
    }
    .category-cnt {
      margin-top: 0.2rem;
    }
  }
  .category-n-type-cnt {
    margin: 0.6rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px) {
      margin: 0px;
      align-items: start;
      flex-direction: column;
    }
    .category-cnt,
    .type-cnt {
      display: flex;
      align-items: center;
    }
    .category-cnt {
      .category {
        font-size: 14px;
        color: #666666;
        font-weight: 600;
        @media (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
    .type-cnt {
      .type {
        font-size: 14px;
        color: #666666;
        font-weight: 600;
        @media (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
  }
}
