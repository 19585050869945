.profile {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  padding-top: 120px;
  .social-media-icon {
    margin: 0.5rem;
  }
  @media (max-width: 480px) {
    .copy-input-link {
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
  .copy-link-cnt {
    display: flex;
    align-items: center;
    input {
      flex: 1;
      margin-right: 8px;
      padding: 8px;
    }
    button {
      padding: 8px 12px;
      background-color: #1d54c4;
      font-weight: 550;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    button.copied {
      background-color: #2196f3;
      /* Blue */
    }
  }
  .share-popup {
    transform: translateX(-50%);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: fixed;
    padding: 15px;
    left: 50%;
    bottom: 45%;
    z-index: 1000;
  }
  .share-icon-cnt {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 380px) {
    .share-icon-cnt {
      flex-wrap: wrap;
    }
  }
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: 650;
    background: none;
    border: none;
    font-size: 18px;
    color: #888;
    cursor: pointer;
  }
  .share-buttons {
    display: flex;
    flex-direction: column;
  }
  .facebook-button,
  .twitter-button,
  .linkedin-button,
  .instagram-button {
    background-color: #14539a;
    color: #fff;
    border: none;
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 5px;
  }
  .facebook-button,
  .twitter-button,
  .linkedin-button,
  .instagram-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin: 0.9rem;
  }
  .facebook-button:hover,
  .twitter-button:hover,
  .linkedin-button:hover,
  .instagram:hover {
    background-color: #217dbb;
  }
  .cover-picture-cnt {
    margin-bottom: 2rem;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .upload-text {
      color: rgb(202, 20, 20);
      margin-top: 8px;
    }
    .img-icon {
      font-size: 35px;
      color: rgb(202, 20, 20);
    }
    .file-input {
      opacity: 0;
      top: 0;
      left: 0;
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 45.6px;
      border-radius: 30px;
      border: 1px solid #ddd;
    }
    .btn-primary {
      height: 54.6px;
      width: 10rem;
      font-size: 14px;
    }
  }
  .search-bar-component {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    .search-bar {
      display: flex;
      width: 60%;
      position: relative;
      height: 50px;
      align-items: center;
      background-color: #fff;
      border-radius: 14px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      @media (max-width: 480px) {
        width: 90%;
      }
      input[type='text'] {
        border: none;
        padding-left: 12px;
        flex-grow: 1;
        border-radius: 4px;
        outline: none;
      }
      .search-icon {
        color: #0073e6;
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        font-size: 18px;
      }
      .store-list {
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
          rgba(17, 17, 26, 0.1) 0px 0px 8px;
        position: absolute;
        z-index: 99999;
        width: 100%;
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        border: 1px solid #ddd;
        border-radius: 7px;
        margin-top: 9px;
        background: white;
        .each-store {
          margin: 0.5rem;
          padding: 0.6rem;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #dcdcdc;
          .store-img {
            margin-right: 1rem;
            height: 70px;
            width: 70px;
            border: 1px solid #ddd;
            border-radius: 50%;
            object-fit: contain;
          }
          .location {
            color: #64676b;
            font-size: 14px;
          }
          .name {
            color: #14539a;
            font-weight: 500;
          }
        }
        .each-store:hover {
          cursor: pointer;
          background-color: #f3f3f2;
          border-radius: 7px;
        }
      }
    }
  }
  .footer-nav {
    position: fixed;
    right: 24px;
    bottom: 0px;
  }
  .sharing-nav-link-cnt {
    .link {
      background-color: #14539a;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      line-height: 2.3rem;
      text-align: center;
      margin-bottom: 0.9rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    a {
      color: white;
      font-size: 1.2rem;
    }
  }
  .banner-n-arrow-cnt {
    margin: 1rem 0;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .arrows {
    height: 30px;
    font-size: 18px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    color: #726b6b;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;
    &:hover {
      background-color: #dcdcdc;
    }
  }
  .left_arrow {
    left: 5px;
  }
  .right_arrow {
    right: 5px;
  }
  .banner-img {
    width: 100%;
    max-height: 300px;
    border-radius: 5px;
    object-fit: contain;
  }
  @media (max-width: 480px) {
    padding: 8px;
    padding-top: 100px;
  }
  .cover-image {
    border: 1px solid #ddd;
    border-radius: 5px;
    img {
      width: 100%;
      max-height: 300px;
      border-radius: 5px;
      object-fit: contain;
    }
  }
  .profile-details {
    margin-top: -70px;
    @media (max-width: 320px) {
      margin-top: -40px;
    }
    .profile-image-n-seller-name {
      margin-left: 2rem;
      display: flex;
      align-items: center;
      @media (max-width: 480px) {
        margin: 0px;
        flex-direction: column;
      }
      .info-like-address-n-contact {
        margin-top: 4.5rem;
        margin-left: 1.5rem;
        display: flex;
        justify-content: space-between;
        flex-basis: 84%;
        align-items: center;
        @media (max-width: 480px) {
          margin: 0rem;
          flex-direction: column;
          text-align: center;
        }
        .seller-name {
          font-size: 23px;
          font-weight: 550;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: 480px) {
            font-size: 17px;
          }
        }
        .icon {
          color: #14539a;
          display: inline;
          margin-right: 7px;
        }
        .location,
        .phone-number,
        .email {
          display: flex;
          align-items: center;
          font-size: 15px;
          color: #7a7777;
          font-weight: 450;
          @media (max-width: 480px) {
            font-size: 12.5px;
            margin-bottom: 0.5rem;
          }
        }
        @media (max-width: 480px) {
          .location {
            margin: 0.5rem 0;
          }
        }
        .email {
          margin-top: 0.13rem;
        }
      }
      .seller-img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: scale-down;
        border: 1px solid #ddd;
        @media (max-width: 480px) {
          width: 100px;
          height: 100px;
          margin-bottom: 0.5rem;
        }
        @media (max-width: 310px) {
          width: 75px;
          height: 75px;
        }
      }
    }
    .home-n-about-us {
      border-bottom: 1px solid #ddd;
      margin-top: 1rem;
      .link {
        cursor: pointer;
        height: 36px;
        line-height: 36px;
        border-radius: 7px;
        text-align: center;
        color: #64676b;
        font-size: 14px;
        font-weight: 550;
        width: 80px;
        display: inline-block;
      }
      .link:hover {
        background-color: #eeeff0c7;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }
      .active-link {
        color: #14539a;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-bottom: 1.8px solid #14539a;
      }
    }
  }
  .seller-info-n-product-cnt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
  .seller-info-section {
    margin-top: 2rem;
    flex-basis: 30%;
    .tag {
      font-size: 17px;
      font-weight: 530;
      margin-bottom: 12px;
    }
    .about-seller,
    .store-photos,
    .store-on-map {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding: 12px;
      border-radius: 8px;
    }
    .store-photos,
    .store-on-map {
      margin-top: 1.5rem;
    }
    .about-seller {
      .description {
        color: #6c7b85;
      }
    }
    .store-photos {
      .store-photos-cnt {
        display: flex;
        flex-wrap: wrap;
        .store-img {
          border: 1px solid #ddd;
          margin: 0.68rem;
          width: 140px;
          height: 140px;
          border-radius: 6px;
          margin-bottom: 1rem;
          display: block;
          object-fit: cover;
          @media (max-width: 480px) {
            width: 100px;
            height: 100px;
            margin: 0.5rem;
          }
        }
      }
    }
  }
  .product-section {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 12px;
    overflow-y: scroll;
    border-radius: 8px;
    margin-top: 2rem;
    flex-basis: 65%;
    height: fit-content;
    .brand-list {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
    }
    .each-brand {
      margin: 1rem;
      flex-basis: 22%;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      @media (max-width: 480px) {
        flex-basis: 100%;
      }
      .brand-logo-img {
        border: 1px solid #ddd;
        height: 100px;
        width: 100px;
        object-fit: contain;
      }
      .brand-name {
        color: black;
        font-weight: 580;
        font-size: 17px;
        margin-top: 1rem;
      }
      .total-product-count {
        color: #656565;
        font-size: 13px;
        margin-top: 0.4rem;
      }
    }
    .heading-of-product-section {
      font-size: 17px;
      font-weight: 530;
      text-align: center;
    }
    .carasouel {
    }
  }
}

.tooltip {
  position: relative;
  display: block;
  .tooltiptext {
    visibility: hidden;
    width: auto;
    background: #222222;
    color: white;
    font-size: smaller;
    border-radius: 5px;
    padding: 6px;
    position: absolute;
    z-index: 1;
    bottom: 12%;
    left: -128%;
    transform: translateX(-50%);
    transition: opacity 0.3s;
    @media (max-width: 480px) {
      font-size: 9px;
      padding: 4px 10px;
    }
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
