.public-store-profile-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  .cover-picture-cnt {
    margin-bottom: 2rem;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .cross-icon-cnt {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 10px;
      color: red;
      background: whitesmoke;
      font-size: 29px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      .cross-icon {
        font-size: 16px;
        cursor: pointer;
      }
    }
    .cover-image {
      width: 100%;
      border-radius: 5px;
      object-fit: contain;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      height: 200px;
    }
    .upload-text {
      color: #666666;
      margin-top: 8px;
    }
    .img-icon {
      font-size: 35px;
    }
    .file-input {
      opacity: 0;
      top: 0;
      left: 0;
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 45.6px;
      border-radius: 30px;
      border: 1px solid #ddd;
    }
  }
  .profile-picture img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: -50px auto 20px;
    background-color: #f0f0f0;
  }
  .about-field textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
    min-height: 100px;
  }
}
