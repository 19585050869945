.product-info-cnt {
  .edit-delete-cnt {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    .delete {
      cursor: pointer;
      color: red;
      margin-left: 0.5rem;
    }
    .edit {
      cursor: pointer;
      color: #14539a;
    }
    @media (max-width: 480px) {
      font-size: 13px;
    }
  }
  .box {
    .range,
    .price {
      color: #6c7b85;
    }
  }
  .edit-form {
    position: fixed;
    padding: 60px 0;
    padding-top: 120px !important;
    z-index: 999;
    width: 100%;
    left: 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    overflow: scroll;
    .sell-modal-form {
      // position: fixed;
      overflow: scroll;
    }
  }
}
