.user-avatar-container {
  position: relative;
  .user_profile {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 0.5rem;
  }
  #store-list-in-header {
    position: absolute;
    top: 110%;
    display: block;
    left: -250px !important;
    width: 270px !important;
    @media (max-width: 360px) {
      top: 114%;
      display: block;
      left: -220px !important;
    }
    @media (max-width: 280px) {
      top: 114%;
      display: block;
      left: -200px !important;
    }
  }
  @media (max-width: 480px) {
    .dropdown-menu {
      width: 250px !important;
      left: -130px !important;
    }
  }
  @media (max-width: 290px) {
    .dropdown-menu {
      width: 200px !important;
      left: -130px !important;
    }
    #store-list-in-header {
      width: 240px !important;
    }
  }
  .dropdown-menu {
    border-radius: 0.5rem;
    z-index: 1;
    display: block;
    width: 360px;
    background: rgb(255, 255, 255);
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      margin: 5px 0;
      padding: 8px;
      &:hover {
        border-radius: 0.4rem;
        background-color: #eee;
      }
      .navigations {
        margin-left: 0.5rem;
        font-size: 0.9rem;
        font-weight: 600;
      }
      .icon-cnt {
        background: #e5e7eb;
        width: 35px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        .react-icon {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      // padding: 5px 10px;
      text-decoration: none;
      color: #333;
    }
  }
}

.fixed_header {
  border-radius: 1rem;
  margin-top: 1rem;
  z-index: 2;
  padding: 20px;
  position: fixed;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 480px) {
    padding: 10px;
  }
  .hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    width: 34px;
    height: 34px;
    font-size: 1.3rem;
    transition: all 0.2s ease-in-out 0s;
    color: #14539a;
  }
  .cross_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    width: 34px;
    height: 34px;
    font-size: 1.3rem;
    transition: all 0.2s ease-in-out 0s;
    color: #14539a;
  }
  .cart_icon {
    width: 24px;
  }
}

.sidebar {
  position: fixed;
  top: 77px;
  left: 60px;
  overflow-y: scroll;
  background: white;
  transition-duration: 1s;
  width: 240px;
  z-index: 912999999999;
  padding: 0 12px;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  @media (max-width: 480px) {
    left: 23px;
  }
}

.login {
  color: #313131;
  font-weight: 500;
  margin-left: 0.9rem;
}

.start-btn {
  color: #14539a;
  font-weight: 480;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_item {
  margin: 1rem 0;
  a {
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    color: #14539a;
    font-size: 0.89rem;
    padding: 10px 16px 10px 24px;
    span {
      font-weight: 550;
      margin-left: 0.6rem;
    }
  }
}

.nav_item:hover {
  background-color: #f1efef;
  border-radius: 1rem;
}

.notification-btn {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #fff;
  color: #333;
  font-size: 12px;
  cursor: pointer;
}

.read-btn:hover,
.unread-btn:hover {
  background-color: #eee;
}

.stores {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-left: 0.3rem;
  margin-right: 0.9rem;
}

.user_profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: scale-down;
  border: 1px solid #ddd;
}

// bottom navbar
.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  left: 0px;
  ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0;
    li {
      list-style: none;
      a {
        color: #14539a;
        font-size: 16px;
        display: block;
        text-align: center;
        &:hover {
          color: darken(#14539a, 10%);
        }
      }
    }
  }
}

// notification icons styles
.notification-icon .notification-menu::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 15px;
  border-style: solid;
  border-width: 0px 10px 10px 10px;
  border-color: transparent transparent #ddd transparent;
}

.notification-menu {
  .title {
    font-size: 19px;
    font-weight: 500;
  }
}

.notification-icon {
  position: relative;
  margin-right: 1.2rem;
}

.notification-icon i {
  font-size: 24px;
  color: #333;
}

.notification-icon .badge {
  position: absolute;
  top: 15px;
  right: -10px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f44336;
  color: #fff;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
}

.notification-icon .notification-menu {
  position: absolute;
  overflow-y: scroll;
  top: 166%;
  right: -12px;
  width: 400px;
  max-height: 300px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid #ddd;
  padding: 12px 22px;
  border-radius: 13px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  @media (max-width: 480px) {
    width: 200px;
    padding: 6px 8px;
    .title {
      flex-basis: 100%;
      font-size: 16px;
    }
    .mark-all-read {
      font-size: 14px;
    }
  }
}

.notification-icon:hover .notification-menu {
  opacity: 1;
  visibility: visible;
}

.notification-icon .notification-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.notification-icon .notification-menu ul li {
  padding: 10px;
  @media (max-width: 480px) {
    padding: 6px;
  }
}

.notification-icon .notification-menu ul li:last-child {
  border-bottom: none;
}

.notification-menu {
  .mark-all-read {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    color: #1877f2;
    font-weight: 450;
  }
  ul {
    li {
      display: flex;
      justify-content: start;
      align-items: center;
      @media (max-width: 480px) {
        flex-wrap: wrap;
      }
      .circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #f44336;
        @media (max-width: 480px) {
          flex-basis: 4%;
        }
      }
      p {
        @media (max-width: 480px) {
          flex-basis: 85%;
        }
        margin-left: 0.5rem;
        font-weight: 450;
        color: #333;
        text-decoration: none;
      }
    }
  }
  li:hover {
    background: #f2f2f2;
    border-radius: 13px;
    color: white;
  }
}
