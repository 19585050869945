.new-order-cnt {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 639px) {
    .new-order-box {
      padding: 19px;
      margin: 0.6rem;
      #button-primary,
      .button-primary {
        margin-left: 1rem !important;
        width: 5rem !important;
      }
      .button-primary,
      .cancel-btn {
        padding: 5px 10px;
        font-size: 12px !important;
      }
    }
  }
  @media (max-width: 480px) {
    .new-order-box {
      padding: 10px;
    }
  }
}

.menu-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: -1.4rem;
  cursor: pointer;
  .circle {
    color: gray;
    height: 8px;
    width: 10px;
    font-size: 38px;
  }
}

.menu-options {
  width: 124px;
  position: absolute;
  top: 52px;
  right: -9px;
  padding: 6px;
  background-color: #fff;
  border-radius: 5px;
  z-index: 9;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  list-style: none;
  li {
    padding: 8px;
    font-weight: 450;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.menu-container:hover .menu-options {
  display: block;
}

.no-order-found {
  text-align: center;
  color: red;
  font-weight: 500;
}

.new-order-box {
  width: 90vw;
  overflow-y: auto;
  max-height: calc(100vh - 120px);
  opacity: 1;
  height: auto;
  padding: 20px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: white;
  @media (max-width: 480px) {
    width: 98vw;
  }
  .new-order {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 15px;
    margin: 1rem 0;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    @media (max-width: 480px) {
      padding: 6px;
    }
    .urgent-order {
      position: absolute;
      top: 10px; /* Half of the label height */
      right:-18px; /* Half of the label width */
      width: 75px;
      height: 20px;
      background-color: #e00; /* Adjust the background color */
      color: white;
      text-align: center;
      line-height: 20px; /* Centers text vertically */
      transform: rotate(45deg); /* Rotates the label */
    }
  }
}

.header-of-accepting-or-declining {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
}

.note-of-accepting-order {
  color: #0f1111 !important;
  background-color: #fff;
  padding: 14px 18px 18px;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  border-left-width: 12px;
  border-color: #1196ab;
  display: flex;
  .info-icon {
    color: #1196ab;
    margin-right: 0.3rem;
    display: inline-block;
    font-size: 24px;
    margin-top: -0.1rem;
  }
  .note-topic {
    font-weight: 700;
  }
  .note-answer {
    font-size: 14px;
    line-height: 20px;
    margin: 0.5rem 0 0.5rem 0;
  }
  @media (max-width: 480px) {
    padding: 7px 9px 9px;
    display: block;
    position: relative;
    .info-icon {
      position: absolute;
      right: 0;
    }
    .note-topic {
      font-size: 14px;
    }
    .note-answer {
      font-size: 12px;
    }
  }
}

.accept-decline-cnt {
  display: flex;
  flex-basis: 90%;
  justify-content: end;
  @media (max-width: 480px) {
    justify-content: space-between;
    margin: 9px 0;
    .two-accept-cnt {
      text-align: right;
    }
  }
  .accept-btn {
    display: inline-block;
    padding: 4px 22px;
    height: 30px;
    font-size: 15px;
    text-align: center;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    @media (max-width: 480px) {
      height: 24px;
      padding: 1px 7px;
      font-size: 12px;
    }
  }
  .accept-btn:hover {
    background-color: #45a049;
  }
  .decline-btn {
    margin-right: 12px;
    padding: 4px 26px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    background-color: #ccc;
    color: #333;
    transition-duration: 480ms;
    @media (max-width: 480px) {
      height: 24px;
      padding: 1px 12px;
      font-size: 12px;
    }
  }
  .decline-btn:hover {
    background-color: red;
    color: whitesmoke;
  }
}

.order-name-n-img-table-data {
  display: flex;
  align-items: center;
}

.order-list {
  .buyer-location,
  .buyer-number,
  .buyer-order-date,
  .buyer-email {
    display: flex;
    align-items: center;
    //
    margin: 2px 0;
    font-size: 13px;
    color: #6e6969;
    font-weight: 450;
    @media (max-width: 480px) {
      margin: 6px 0;
      font-size: 14px;
    }
  }
  .buyer-location-icon,
  .buyer-phone-icon,
  .buyer-date,
  .buyer-email-icon {
    margin-right: 5px;
    color: #14539a;
    margin-top: 2px;
  }
  .buyer-location {
    margin: 7px 0;
    align-items: start !important;
    @media (max-width: 480px) {
      font-size: 13px !important;
    }
  }
  .accept-decline-cnt {
    flex-basis: 100%;
    margin-top: 7px;
  }
  .cross-icon-cnt {
    display: flex;
    justify-content: end;
    .cross-icon {
      display: block;
      color: red;
      font-size: 18px;
    }
  }
  .cnt-of-seller-img-n-seller-info {
    display: flex;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: center;
      flex-basis: 100%;
      flex-wrap: wrap;
      justify-content: center;
    }
    @media (max-width: 480px) {
      .buyer-name-n-location {
        text-align: center;
      }
    }
    .seller-img {
      color: #14539a;
      width: 100px;
      height: 100px;
      border-radius: 13px;
      @media (max-width: 480px) {
        width: 80px;
        height: 80px;
      }
    }
  }
  .buyer-name {
    text-transform: uppercase;
    font-size: 15.5px;
    font-weight: 500;
    @media (max-width: 480px) {
      font-size: 14px;
      margin: 8px 0;
    }
  }
}

@media (max-width: 480px) {
  .buyer-date-n-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
  }
}

.see-order-items-cnt {
  display: flex;
  justify-content: center;
  color: #14539a;
  margin: 6px 0;
  .see-order-items {
    cursor: pointer;
    margin-top: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 15px;
    @media (max-width: 480px) {
      margin-top: 0rem;
      font-size: 14px;
    }
  }
}

.bill-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  .order-img {
    object-fit: contain;
    width: 50px;
    height: 50px;
    margin-right: 0.5rem;
  }
  .order-item-name {
    font-size: 0.89rem;
    font-weight: 450;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  tr {
    border-radius: 0.5rem;
  }
  td {
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }
  th {
    color: #6d7785;
    font-size: 13.5px;
    font-weight: 550;
    background-color: #f2f2f2;
    padding: 10px;
    text-align: center;
  }
  .grand-total {
    text-align: left;
    color: rgb(109, 119, 133);
    font-size: 19px;
    font-weight: 550;
  }
}

.cancel-btn {
  text-decoration: underline;
  color: #626d75;
  font-weight: 450;
}

// order items styles for mobile
.order-items-mb {
  .heading-mb {
    font-size: 13px;
    color: #6e6969;
    font-weight: 650;
  }
  .order-item-detail {
    padding: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    .flex {
      display: flex;
    }
    .justify-between {
      justify-content: space-between;
    }
    .padding-6 {
      padding: 4.5px;
    }
  }
  .img-n-name {
    display: flex;
    align-items: center;
    .order-item-name {
      margin-left: 16px;
    }
    .order-item-img {
      object-fit: contain;
      width: 40px;
      height: 40px;
      margin-right: 0.5rem;
    }
  }
}
