.cnt-of-product-filter {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  padding: 8px;
  left: 0;
  position: fixed;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  input[type='checkbox'] {
    display: inline;
    margin-right: 10px;
  }
  @media (max-width: 639px) {
    .confirmation-box {
      padding: 19px;
      margin: 0.6rem;
      p {
        font-size: 0.9rem;
      }
      #button-primary,
      .button-primary {
        margin-left: 1rem !important;
        width: 5rem !important;
      }
      .button-primary,
      .cancel-btn {
        padding: 5px 10px;
        font-size: 12px !important;
      }
    }
  }
  .or-cnt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-basis: 100%;
    .border {
      border: 0.15px solid #ddd;
      flex-basis: 40%;
      height: 0px;
      margin-top: 1.5rem;
    }
    .or {
      flex-basis: 15%;
      font-size: 31px;
      color: #c1bfbfbf;
      text-align: center;
    }
  }
}

.filter-form {
  width: 450px;
  opacity: 1;
  height: auto;
  padding: 22px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: white;
  .heading {
    font-weight: 600;
    font-size: larger;
    border-bottom: 1px solid #ddd;
    padding-bottom: 9px;
    display: flex;
    justify-content: space-between;
    color: var(--second-priority-color);
    h2 {
      display: flex;
      align-items: center;
    }
    .cross-icon {
      color: red;
    }
  }
  .input-label {
    align-self: start;
    font-size: 15px;
    color: #666666;
    font-weight: 480;
  }
  .autocomplete-container {
    margin-bottom: 8px;
    position: relative;
    .suggestions-list {
      top: 72px;
    }
    .suggestions-list {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      max-height: 120px;
      overflow-y: scroll;
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 4px 4px;
      position: absolute;
      background-color: white;
      z-index: 1;
      .suggestion-item {
        padding: 8px 16px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 430;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
  .selected-route {
    color: #666666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ddd;
    width: 100% !important;
    padding: 8px;
    font-weight: 450;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
  }
  .question-yes-or-no {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #666666;
    margin-bottom: 5px;
    font-weight: 550;
  }
  .apply-filter-btn-cnt {
    margin-top: 0.6rem;
    .apply-filter-btn {
      height: 37px;
      width: 100%;
      background-color: rgb(38, 102, 177);
      border: none;
      border-radius: 20px;
      color: white;
      padding: 5px 10px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  }
}

.cancel-btn {
  text-decoration: underline;
  color: #626d75;
  font-weight: 450;
}
