input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-number-cnt {
  position: relative;
  height: 48px;
  .input-number::-webkit-inner-spin-button,
  .input-number::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Hide Firefox increment button */
  .input-number {
    -moz-appearance: textfield;
  }
  /* Hide IE 10+ increment button */
  .input-number::-ms-updown-inner {
    display: none;
  }
  .label {
    position: absolute;
    top: 24%;
    margin-left: 1.3rem;
    color: #9ea6b3;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  .input-number {
    height: 100%;
    padding: 0 1.25rem;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    border: 1px solid #e4e7eb;
    width: 100%;
    transition: border-color 0.3s ease;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    }
  }
  .input-number:focus {
    border: 1px solid #3777c0;
  }
  .label-should-move-upward {
    color: #3777c0;
    top: -9px;
    left: -11px;
    font-size: 13px;
    display: inline;
    background: white;
    padding: 0 5px;
    transition: all 0.3s ease;
  }
}

.confirmation-cnt {
  width: 100vw;
  height: 110vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 639px) {
    .confirmation-box {
      padding: 19px;
      margin: 0.6rem;
      p {
        font-size: 0.9rem;
      }
      #button-primary,
      .button-primary {
        margin-left: 1rem !important;
        width: 5rem !important;
      }
      .button-primary,
      .cancel-btn {
        padding: 5px 10px;
        font-size: 12px !important;
      }
    }
  }
}

.confirmation-box {
  width: 500px;
  opacity: 1;
  height: auto;
  padding: 20px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: white;
  p {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
  }
}

.cancel-btn {
  text-decoration: underline;
  color: #626d75;
  font-weight: 450;
}
