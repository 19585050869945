.productListCntMb {
  .productMb {
    padding: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    background: rgba(232, 233, 240, 0.51);
    .seeProductDetail,
    .hideProductDetail {
      color: #14539a;
      font-size: 14px;
      padding-bottom: 12px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
    .imgAndName {
      display: flex;
      align-items: center;
      .productImg {
        object-fit: contain;
        width: 40px;
        height: 40px;
        margin-right: 0.5rem;
      }
      .productName {
        margin-left: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .productDetailInRow {
      padding: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .detail {
        font-size: 13px;
        color: #6e6969;
        font-weight: 650;
      }
    }
  }
}
