.buyer-store-map-cnt {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .buyer-store-map {
    width: 50%;
    height: 50%;
    border-radius: 16px;
    background-color: white;
    padding: 19px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}

.cross-icon-cnt {
  margin-bottom: 13px;
  display: flex;
  justify-content: end;
  .cross-icon {
    display: block;
    color: red;
    font-size: 18px;
  }
}
