.all-store-cnt {
  padding-top: 120px;
  .search-bar-component {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    .search-bar {
      display: flex;
      width: 50%;
      position: relative;
      height: 65px;
      align-items: center;
      background-color: #fff;
      border-radius: 14px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      @media (max-width: 480px) {
        width: 90%;
      }
      input[type='text'] {
        border: none;
        padding-left: 12px;
        flex-grow: 1;
        border-radius: 4px;
        outline: none;
      }
      .search-icon {
        color: #0073e6;
        background: none;
        border: none;
        cursor: pointer;
        padding: 10px;
        font-size: 18px;
      }
      .store-list {
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
          rgba(17, 17, 26, 0.1) 0px 0px 8px;
        position: absolute;
        z-index: 99999;
        width: 100%;
        position: absolute;
        top: 100%;
        width: 100%;
        max-height: 300px;
        overflow-y: scroll;
        border: 1px solid #ddd;
        border-radius: 7px;
        margin-top: 9px;
        background: white;
        .each-store {
          margin: 0.5rem;
          padding: 0.6rem;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #dcdcdc;
          .store-img {
            margin-right: 1rem;
            height: 70px;
            width: 70px;
            border: 1px solid #ddd;
            border-radius: 50%;
            object-fit: contain;
          }
          .location {
            color: #64676b;
            font-size: 14px;
          }
          .name {
            color: #14539a;
            font-weight: 500;
          }
        }
        .each-store:hover {
          cursor: pointer;
          background-color: #f3f3f2;
          border-radius: 7px;
        }
      }
    }
  }
  .seller-list {
    padding: 1rem 0;
    @media (max-width: 480px) {
      padding: 0rem 0;
    }
    .arrow-right {
      font-size: 1.2rem;
      margin-top: -0.1rem;
    }
    .flex-49 {
      flex: 0 1 30%;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      @media (max-width: 480px) {
        flex: 0 1 100%;
      }
    }
    .text-center {
      text-align: center;
    }
    .font-0 {
      font-size: 0;
    }
    .seller-list-container {
      width: 100%;
      margin: 0 auto;
      padding: 0 2rem;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 480px) {
        padding: 0 0.7rem;
      }
    }
    .flex-49 {
      position: relative;
      margin: 1.6rem;
      border-radius: 1rem;
    }
    @media (max-width: 480px) {
      .flex-49 {
        margin: 0rem;
        margin-bottom: 1rem !important;
      }
    }
    .small-article {
      color: #313131;
      border: 0.5px solid #ddd;
      border-radius: 6px;
      position: absolute;
      padding: 1.4rem;
      bottom: 1.7rem;
      left: 1.7rem;
      right: 1.7rem;
      background-color: rgba(254, 254, 255, 0.89);
      @media (max-width: 480px) {
        padding: 1rem;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
      }
      h4 {
        font-size: 1.3rem;
        font-weight: 700;
      }
      a {
        text-decoration: none;
        color: #676565;
        font-size: 0.8rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        font-size: 0.99rem;
        font-weight: 450;
        margin: 1rem 0;
      }
      span {
        font-size: 0.6rem;
        font-weight: 200;
        margin-right: 0.2rem;
        margin-bottom: -0.2rem;
      }
    }
    .small-article:hover {
      // background-color: #a33f42;
      background-color: #181740;
      transition-duration: 800ms;
      color: white;
    }
    .small-article:hover a {
      color: white;
    }
    .figure-1 img {
      border-radius: 6px;
      object-fit: cover;
      height: 400px;
      width: 100%;
    }
  }
}
