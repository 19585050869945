.input-text-cnt {
  position: relative;
  height: 48px;
  .label {
    position: absolute;
    top: 24%;
    margin-left: 1.3rem;
    color: #9ea6b3;
    transition: all 0.3s ease;
  }
  .input-text {
    height: 100%;
    padding: 0 1.25rem;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    border: 1px solid #e4e7eb;
    width: 100%;
    transition: border-color 0.3s ease;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    }
  }
  .input-text:focus {
    border: 1px solid #3777c0;
  }
  .label-should-move-upward {
    color: #3777c0;
    top: -9px;
    left: -11px;
    font-size: 13px;
    display: inline;
    background: white;
    padding: 0 5px;
    transition: all 0.3s ease;
  }
  .error_text {    
      color: red;
  }
  .error:focus {
    border:1px solid red;
    transition: border-color 0.3s ease
  }
}
