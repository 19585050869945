.brand-list-cnt {
  // max-width: 80%;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  position: relative;
  padding-top: 120px;
  @media (max-width: 480px) {
    padding: 0 6px;
    padding-top: 100px;
  }
  .brand-form-cnt {
    z-index: 9;
    background: rgb(0 0 0 / 50%);
    padding: 0px !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .heading {
    color: #14539a;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  .brand-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    @media (max-width: 480px) {
      justify-content: center;
    }
    @media (max-width: 400px) {
      flex-direction: column;
    }
    .each-brand {
      margin: 1rem;
      flex-basis: 22%;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      .brand-name {
        color: black;
        font-weight: 580;
        font-size: 17px;
        margin-top: 1rem;
        @media (max-width: 480px) {
          margin-top: 0.5rem;
        }
      }
      @media (max-width: 480px) {
        flex-basis: 40%;
        padding: 9px;
      }
    }
    .brand-logo-cnt {
      display: flex;
      justify-content: center;
      align-items: center;
      .brand-logo-img {
        border: 1px solid #ddd;
        height: 100px;
        width: 100px;
        object-fit: contain;
      }
    }
    .total-product-count {
      color: #656565;
      font-size: 13px;
      margin-top: 0.4rem;
    }
    .add-product-btn {
      z-index: -1 !important;
    }
    .links {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      margin-top: 0.4rem;
      color: #14539a;
      cursor: pointer;
      @media (max-width: 480px) {
        font-size: 12px;
      }
      .icon {
        margin-right: 0.5rem;
      }
    }
  }
}
