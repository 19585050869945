.edit-seller-product-detail-form {
  background: #fff;
  width: 50vw;
  position: relative;
  overflow: scroll;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  @media (max-width: 480px) {
    width: 95vw;
    padding: 8px;
  }
  .checkbox-group {
    display: flex;
    flex-direction: column;
    .form-control-text {
      margin-bottom: 1rem;
    }
  }
  .back-n-h1-cnt {
    margin-bottom: 1rem;
  }
  input[type='checkbox'] {
    display: inline-block;
  }
  .heading {
    font-size: 15px;
    color: #14539a;
  }
  .option-cnt {
    display: flex;
    flex-direction: column;
    width: 100%;
    .option-n-checkbox {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-weight: 410;
      .checkbox {
        margin-right: 10px;
        height: 18px;
        width: 18px;
        border-radius: 5px;
        border: 2px solid #ccc;
        cursor: pointer;
      }
    }
  }
  .edit-checkbox {
    margin-right: 10px;
    height: 12px;
    width: 18px;
    border-radius: 5px;
    border: 2px solid #ccc;
    cursor: pointer;
  }
  .update-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    color: #14539a;
    margin: 0 1rem;
    margin-bottom: 1.5rem;
    margin-top: 0.9rem;
    font-size: 0.92rem;
    padding: 0.8rem;
    flex-basis: 33%;
    color: rgb(222, 230, 240);
    background: #14539a;
  }
}
