/* Add a tooltip container */

.tooltip {
  position: relative;
  display: block;
  .tooltiptext {
    visibility: hidden;
    max-width: 200px;
    border: 1px solid #ddd;
    color: #14539a;
    text-align: center;
    background: whitesmoke;
    border-radius: 5px;
    padding: 6px 20px;
    position: absolute;
    z-index: 1;
    bottom: -10%;
    left: 23%;
    font-size: 14px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    @media (max-width: 480px) {
      font-size: 9px;
      padding: 4px 10px;
    }
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Show the tooltip text when hovering over the tooltip container */

.product-list-in-pop-up-mode {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  position: fixed;
  padding: 20px;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  .table-row {
    flex-direction: row !important;
  }
  @media (max-width: 480px) {
    padding: 8px;
  }
  .product-name-n-checkbox {
    display: flex;
    justify-content: space-between;
    color: #14539a;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    @media (max-width: 480px) {
      flex-wrap: nowrap;
    }
    .checkbox {
      flex-basis: 10%;
      height: 18px;
      .input-type-checkbox {
        display: inline-block;
        cursor: pointer;
      }
      @media (max-width: 480px) {
        margin-right: 0.3rem;
      }
    }
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      flex-basis: 90%;
      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
  .product-img {
    border-radius: 8px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    @media (max-width: 480px) {
      width: 60px;
      height: 60px;
    }
  }
}
