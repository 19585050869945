.cnt-of-buyer-page {
  width: 100%;
  margin: 0 auto;
  padding-top: 120px;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  .padding-14 {
    padding: 13px;
    @media (max-width: 480px) {
      padding: 8px;
    }
  }
  .store-list {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
    position: absolute;
    z-index: 99999;
    width: 100%;
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    border-radius: 7px;
    margin-top: 9px;
    background: white;
    .each-store {
      margin: 0.5rem;
      padding: 0.6rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dcdcdc;
      .store-img {
        margin-right: 1rem;
        height: 70px;
        width: 70px;
        border: 1px solid #ddd;
        border-radius: 50%;
        object-fit: contain;
      }
      .location {
        color: #64676b;
        font-size: 14px;
      }
      .name {
        color: #14539a;
        font-weight: 500;
      }
    }
    .each-store:hover {
      cursor: pointer;
      background-color: #f3f3f2;
      border-radius: 7px;
    }
  }
  .heading-of-buyer-page {
    color: rgb(20, 83, 154);
    font-size: 19px;
    padding: 0 10px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-bottom: 9px;
    @media (max-width: 480px) {
      font-size: 16px;
    }
    @media (max-width: 380px) {
      font-size: 13px;
    }
  }
  .delete {
    flex-basis: 100%;
    display: flex;
    justify-content: end;
    color: #e50e0e;
    cursor: pointer;
  }
  .order-page-link,
  .reassign-buyer,
  .assigned-or-not {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #14539a;
    font-weight: 350;
    margin: 4px 0;
    .icon {
      color: #14539a;
      margin-right: 5px;
    }
  }
  .order-page-link {
    color: #14539a;
  }
}

.search-bar-cnt {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  .search-bar {
    display: flex;
    flex-basis: 40%;
    height: 65px;
    align-items: center;
    background-color: #fff;
    border-radius: 14px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    @media (max-width: 480px) {
      flex-basis: 80%;
      height: 49px;
    }
    @media (max-width: 380px) {
      flex-basis: 67%;
    }
    input[type='text'] {
      border: none;
      padding-left: 12px;
      flex-grow: 1;
      border-radius: 4px;
      outline: none;
    }
    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 10px;
      font-size: 26px;
      color: #555;
      @media (max-width: 480px) {
        font-size: 20px;
      }
    }
    .search-icon {
      color: #0073e6;
    }
  }
  .filter-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 7px;
    height: 63px;
    width: 53px;
    margin-left: 1rem;
    z-index: 1;
    @media (max-width: 480px) {
      height: 42px;
      width: 33px;
    }
    img {
      width: 18px;
      @media (max-width: 480px) {
        width: 14px;
      }
    }
  }
}

// correct it bro
.cnt_of_order {
  width: 100%;
  margin: 0 auto;
  padding: 32px;
  padding-top: 120px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.cnt-list-of-buyer {
  flex-basis: 66%;
  background-color: white;
  border-radius: 0.5rem;
  padding: 0 22px;
  @media (max-width: 480px) {
    padding: 0px 12px;
  }
}

.all-buyer-cnt {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4rem;
  .buyer {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 9px;
    margin: 16px;
    flex-basis: 22%;
    border-radius: 6px;
    @media (max-width: 480px) {
      padding: 6px;
      margin: 0px;
      margin-bottom: 20px;
      flex-basis: 100%;
    }
    .buyer-info {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      @media (max-width: 480px) {
        flex-direction: column;
      }
    }
    .buyer-number,
    .buyer-order-date,
    .buyer-email {
      justify-content: center;
      display: flex;
      align-items: center;
      margin: 4px 0;
      font-size: 14px;
      color: #6e6969;
      font-weight: 350;
    }
    .buyer-phone-icon,
    .buyer-date,
    .buyer-email-icon {
      margin-right: 5px;
      color: #14539a;
      margin-top: 2px;
      font-size: 12px;
    }
    .location,
    .route-name {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 13px;
      color: #6e6969;
      font-weight: 350;
      margin: 4px 0;
    }
  }
}

.cnt-of-buyer-img-n-buyer-info {
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 480px) {
    .buyer-name-n-location {
      text-align: center;
    }
  }
  .buyer-img {
    color: #14539a;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: contain;
    border: 1px solid #ddd;
    @media (max-width: 480px) {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
}

.buyer-name {
  text-transform: uppercase;
  font-size: 15.5px;
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 14.5px;
  }
}

@media (max-width: 480px) {
  .buyer-date-n-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
  }
}

.view-schedule-and-view-buyer-link {
  cursor: pointer;
  display: flex;
  flex-basis: 33%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #14539a;
  font-size: 0.8rem;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  @media (max-width: 480px) {
    justify-content: flex-start;
    font-size: 12px;
  }
  .icon {
    font-weight: 800;
    margin-right: 0.4rem;
  }
}