.dropdown {
  position: absolute;
  bottom: 200px;
  right: 120px;
  z-index: 999999;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 2px 4px rgba(0, 0, 0, 0.2);
  padding: 8px;
}

.btn-primary {
  margin-top: 0rem;
}

.area-input {
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  height: 40px;
  outline: none;
}

.dropdown-menu ul {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.2s ease;
  font-size: 16px;
  color: #333;
}

.dropdown-menu li:hover {
  background-color: #f5f5f5;
  color: #000;
}

.dialog-box-cnt {
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
  position: fixed;
  left: 0px;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  .dialog-box {
    max-width: 400px;
    width: 90%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 99999999999999999999999999999999999;
  }
}

.dialog-box p {
  margin: 20px 0;
  margin: 14px 0;
  color: #666666;
  font-weight: 600;
}

.dialog-box button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin: 10px;
}

.dialog-box button.yes {
  background: rgb(222, 230, 240);
  color: #14539a;
}

.dialog-box button.no {
  background: rgb(253 245 245);
  color: red;
}

.delete-icon {
  font-size: 24px;
  color: #ff0000;
  cursor: pointer;
}

.edit-polygon-icon {
  color: #14539a !important;
  margin: 0 0.8rem;
}

.polypage-cnt {
  padding: 22px;
  padding-top: 125px;
  @media (max-width: 480px) {
    padding: 10px;
    .pop-mode-polygon-lists {
      height: 80vh;
      width: auto;
      border-radius: 0.4rem;
      background-color: rgba(0, 0, 0, 0.5);
      top: 76px;
      left: 10px;
      right: 10px;
      position: absolute;
      padding: 0px 8px;
      z-index: 1500;
      display: flex;
      justify-content: center;
      align-items: center;
      .add-lists-of-polygon-container {
        max-height: 600px !important;
        position: static !important;
      }
      .close {
        width: 150px;
        margin-bottom: 8px;
        display: flex;
        justify-content: end;
        text-align: right;
        color: red;
        font-size: 0.9rem;
      }
    }
    .active-polygon-mobile {
      padding: 0.7rem;
      width: 100px;
      text-align: center;
      background: white;
      border-radius: 8px;
      font-size: 0.7rem;
      font-weight: 800;
      position: absolute;
      bottom: 15px;
      left: 15px;
      .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .open-areas-lists {
        font-size: 1.3rem;
        position: absolute;
        top: -10px;
        right: 30%;
      }
    }
    .learn-distributing-area-info {
      right: 16px !important;
      margin-left: 26px !important;
      bottom: 167px !important;
    }
    .polygon-map-search-bar {
      width: 230px !important;
      padding: 8px !important;
      border-radius: 8px !important;
      padding-left: 8px;
    }
    .btn-primary {
      font-size: 0.8rem !important;
      padding: 5px !important;
      height: 35px !important;
      width: 95px !important;
      margin-top: 1rem;
    }
    .continue-to-product-btn {
      width: 100% !important;
    }
    .button-container {
      button {
        padding: 6px !important;
        font-size: 0.8rem !important;
      }
    }
  }
}

.add-lists-of-polygon-container {
  border-radius: 0.5rem;
  position: absolute;
  bottom: 90px;
  left: 40px;
  width: 250px;
  max-height: 200px;
  overflow-y: scroll;
  @media (max-width: 480px) {
    left: 20px;
    max-height: 300px;
  }
  .polygons {
    background: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    overflow-y: scroll;
    &.open {
      max-height: 400px;
      @media (max-width: 480px) {
        max-height: 250px;
      }
    }
    .area-input {
      padding: 0.2rem 0.5rem;
      border-radius: 8px;
      border: 0.8px solid #ddd;
      margin-left: 0.7rem;
      width: 100%;
      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
    .serial-n-area-input,
    .edit-n-delete {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .polygon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0.7rem;
      border-radius: 5px;
      border-bottom: 0.5px solid #ddd;
      cursor: pointer;
      .area-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .polygon:hover {
      background-color: #f4f4f4;
    }
    .btn-primary.add-polygon {
      margin-top: 10px;
      background-color: #14539a;
      color: white;
      width: 100%;
      @media (max-width: 480px) {
        font-size: 12px;
        width: 100% !important;
      }
    }
  }
  .btn-primary.top-arrow,
  .btn-primary.bottom-arrow {
    width: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 25px;
    border-bottom: 0.5px solid #ddd !important;
    background-color: #fff;
    color: #333;
    border: none;
    font-size: 18px;
    cursor: pointer;
    &:focus {
      background-color: red;
    }
    &:hover {
      background-color: #eee;
    }
  }
  .btn-primary.bottom-arrow {
    top: auto;
    bottom: 10px;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &.cancel-button {
      background-color: #ccc;
      color: #333;
    }
    &.update-button {
      background-color: #14539a;
      color: #fff;
    }
  }
}

/*# sourceMappingURL=polygon.css.map */

// loading indicator styles
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
