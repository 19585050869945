/* Custom Dropdown Styles */

.decline-order-box {
  overflow: hidden;
  .custom-dropdown {
    position: relative;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    .selected-option {
      padding: 5px;
      font-size: 14px;
      background-color: white;
      border-radius: 5px;
    }
    .options {
      display: none;
      font-size: 14px;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      .option {
        padding: 7px;
        cursor: pointer;
        transition: background-color 0.2s;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
    &:hover .options {
      display: block;
    }
  }
  .label {
    margin-top: 13px;
    font-size: 14px;
    display: block;
    color: #666666;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .text-area {
    border: 1px solid #ddd;
    width: 100%;
    margin-top: 1rem;
    border-radius: 3px;
    padding: 5px;
  }
}

.heading-of-seller-route {
  font-size: 1.2rem;
  color: #14539a;
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
}

.seller-route-button-container {
  display: flex;
  justify-content: end;
  margin-top: 12px;
  .submit-button,
  .cancel-button {
    padding: 4px 26px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    @media (max-width: 480px) {
      padding: 4px 12px;
      font-size: 12px;
    }
  }
  .submit-button {
    background-color: #14539a;
    color: #fff;
  }
  .cancel-button {
    background-color: #ccc;
    color: #333;
  }
}

.add-new-route-btn {
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 100%;
  font-size: 15px;
  bottom: 230px;
  width: auto;
  height: 42px;
  margin-top: 1rem;
  border-radius: 5px;
  color: white;
  display: flex;
  padding: 19px;
  justify-content: center;
  span {
    margin-right: 6px;
  }
}

.route-dropdown {
  max-height: 300px;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  align-items: center;
  overflow-y: auto;
  margin: 0 auto;
  overflow-y: scroll;
  .buyer-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .buyer-img {
      object-fit: contain;
      border: 1px solid #ddd;
      color: #14539a;
      width: 100px;
      border-radius: 100px;
      height: 100px;
      @media (max-width: 480px) {
        width: 70px;
        height: 70px;
      }
    }
    .buyer-name {
      margin: 12px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #14539a;
      text-transform: uppercase;
      font-size: 15.5px;
      font-weight: 500;
      text-align: center;
      @media (max-width: 480px) {
        font-size: 13.5px;
      }
    }
  }
  .cnt-of-route-card {
    border: 1px solid #ddd;
    padding: 18px;
    border-radius: 8px;
  }
  .card {
    width: 350px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    display: flex;
    margin-bottom: 12px;
    max-width: 100%;
    align-items: center;
    padding: 10px;
  }
  .active-card {
    background-color: #e4e6e6c9;
    width: 350px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    display: flex;
    margin-bottom: 12px;
    max-width: 100%;
    align-items: center;
    padding: 10px;
  }
  .card:hover {
    background-color: #f5f5f5;
  }
  .icon {
    margin-right: 0.5rem;
    color: #14539a;
    font-size: 18px;
  }
  .route-info {
    flex: 4;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #333;
      @media (max-width: 480px) {
        font-size: 13.5px;
      }
    }
    .schedule {
      margin-top: 5px;
      display: flex;
      font-size: 14px;
      color: #777;
      @media (max-width: 480px) {
        font-size: 12.5px;
      }
    }
  }
}
