.bill-of-order {
  .bill-table {
    border-collapse: collapse;
    border: 0.1px solid black;
    td {
      border: 0.1px solid;
      border-collapse: collapse;
    }
    thead {
      background-color: #fff;
      color: black;
    }
  }
  .seller-store-detail {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .seller-name {
      font-size: 20px;
      font-weight: 700;
    }
    .seller-address {
      font-size: 17px;
    }
    .seller-email {
      font-size: 15px;
    }
  }
}
