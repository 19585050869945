.parent-cnt {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cnt-brand-form {
  width: 500px;
  margin: 0 auto;
  padding: 20px 26px;
  border: 0.5px solid #dddddd;
  background-color: white;
  border-radius: 8px;
  @media (max-width: 480px) {
    margin: auto 0.5rem;
    padding: 10px;
  }
  .h1-n-cross-cnt {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 19px;
    flex-wrap: wrap;
    h1 {
      color: #14539a;
      font-weight: 480;
      font-size: 23px;
      line-height: 1.2;
      @media (max-width: 480px) {
        font-size: 16px;
      }
    }
    .cross-icon {
      color: red;
      font-size: 23px;
    }
  }
  .create-brand-btn {
    background-color: #14539a;
    border-radius: 7px;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    height: 38px;
    font-weight: 400;
    font-size: 15px;
    outline: none;
    outline: 0;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    border-radius: 50px;
    width: 8rem;
    @media (max-width: 480px) {
      height: 40px;
      font-size: 0.8rem;
    }
  }
}

.autocomplete {
  position: relative;
  .autocomplete-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    &:focus {
      border-color: #007bff;
    }
    @media (max-width: 480px) {
      font-size: 13px;
    }
  }
  .autocomplete-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    max-height: 200px;
    overflow-y: auto;
    @media (max-width: 480px) {
      font-size: 13px;
    }
    li {
      padding: 10px;
      font-weight: 550;
      cursor: pointer;
      border-bottom: 0.4px solid #ddd;
      margin: 0.3rem;
      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}

.upload-container {
  padding: 10px;
  margin: 10px 0px;
  border: 3px dotted #ccc;
  border-radius: 5px;
  label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    @media (max-width: 480px) {
      font-size: 13px;
    }
  }
  .file-icon {
    width: 100%;
    display: inline-block;
    width: 20px;
    height: 20px;
  }
}

.image-preview {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border: 2px solid #eaeaea;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  .heading-proof {
    color: #14539a;
  }
}

.image-preview img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.remove-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.remove-icon::before,
.remove-icon::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #333333;
}

.remove-icon::before {
  transform: rotate(45deg);
}

.remove-icon::after {
  transform: rotate(-45deg);
}
