:root {
  --disabled-color-of-button: #e5e7eb;
  --second-priority-color: #14539a;
}

input[type='checkbox'] {
  display: none;
}

.new-order-btn-cnt {
  text-align: end;
  .order-button {
    filter: drop-shadow(0 4px 8px rgba(255, 255, 255, 0.15))
      drop-shadow(0 1px 3px rgba(255, 255, 255, 0.3));
    position: relative;
    color: #fff;
    background-color: #14539a;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 560;
    font-size: 13px;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;
    .order-count {
      position: absolute;
      top: -3px;
      right: -3px;
      background-color: #ff5733;
      color: #fff;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      animation: zoomInOut 1s alternate infinite;
    }
    @keyframes zoomInOut {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}

@keyframes shine {
  0% {
    left: -100px;
  }
  20% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.order-button:before {
  content: '';
  // position: absolute;
  // width: 100px;
  // height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation-delay: 0ms;
  animation: shine 10s infinite ease-in-out;
  animation-play-state: running;
}

.order-button:before {
  content: '';
  // position: absolute;
  // width: 100px;
  // height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  animation: shine 10s infinite ease-in-out;
  animation-delay: 0ms;
  animation-play-state: running;
}

.cnt_of_order {
  width: 100%;
  margin: 0 auto;
  padding: 32px;
  padding-top: 90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  @media (max-width: 480px) {
    padding: 10px !important;
    padding-top: 130px !important;
  }
  .breadcrumb {
    flex-basis: 100%;
    margin-bottom: 1rem;
    margin-left: 1.5rem;
  }
  .change-order-status {
    font-size: 14px;
    color: #14539a;
    cursor: pointer;
    @media (max-width: 480px) {
      margin-top: 0.3rem;
    }
  }
}

.filterIcon {
  z-index: 8;
  position: fixed;
  bottom: 18px;
  right: 18px;
  font-weight: 900;
  font-size: 19.5px;
  display: none;
  align-items: center;
  justify-content: center;
  color: var(--product-bg-color);
  width: 40px;
  height: 40px;
  background: var(--main-color);
  border-radius: 70px;
}

.cnt-of-seller {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 480px) {
    align-items: stretch;
    flex-direction: column;
  }
}

.filters {
  background-color: white;
  flex-basis: 30%;
  border-radius: 0.5rem;
  padding: 0 22px;
  .heading-filter-n-cross-icon {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(209, 208, 208, 0.877);
    h2 {
      font-size: 16px;
      padding-bottom: 8px;
      display: flex;
      align-items: center;
    }
    .go-back-button {
      display: none;
      background-color: white;
      /* Green */
      color: #14539a;
      border-radius: 50px;
      text-align: center;
      text-decoration: none;
      font-size: 22px;
      cursor: pointer;
      padding-bottom: 12px;
      transition: background-color 0.3s;
      @media (max-width: 480px) {
        margin-right: 1rem;
        display: inline-block;
      }
    }
    @media (max-width: 480px) {
      .filter-icon {
        color: white;
      }
    }
  }
  .cnt_of_brand_distributor,
  .cnt_of_brand_distributor-mobile {
    .apply-filter-btn {
      height: 37px;
      width: 100%;
      background-color: rgba(38, 102, 177);
      border: none;
      border-radius: 20px;
      color: white;
      padding: 5px 10px;
      font-size: 13px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: darken(rgba(38, 102, 177), 10%);
      }
    }
  }
  .cnt_of_brand_distributor-mobile {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .autocomplete-container {
    display: flex;
    flex-direction: column;
    position: relative;
    .autocomplete-label {
      font-size: 15px;
      font-weight: 600;
      color: #5b5959;
      margin-bottom: 0.5rem;
    }
    .autocomplete-input {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 260px;
      font-size: 16px;
    }
    .suggestions-list {
      list-style: none;
      padding: 0;
      margin: 0;
      top: 76px;
      width: 100%;
      max-height: 120px;
      overflow-y: auto;
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 4px 4px;
      position: absolute;
      background-color: white;
      z-index: 9;
    }
    .suggestion-item {
      padding: 8px 16px;
      cursor: pointer;
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}

.cnt_lists_of_distributors {
  flex-basis: 66%;
  background-color: white;
  border-radius: 0.5rem;
  padding: 22px;
  .cnt_buy_again {
    border-bottom: 1px solid #ddd;
    .order_page_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.94rem;
      li {
        padding-bottom: 13px;
        color: #14539a;
        flex-basis: 25%;
        text-align: center;
      }
      .active-nav {
        font-weight: 650;
        width: 100%;
        border-bottom: 1.2px solid #14539a;
      }
    }
  }
}

.margin-left {
  margin-left: 1rem;
}

@media (max-width: 480px) {
  .margin-left {
    margin-left: 0rem;
  }
  .total-price-n-place-order-btn-cnt {
    margin-top: 15px;
    justify-content: center !important;
    align-items: center;
    flex-direction: column-reverse;
    flex-basis: 100% !important;
  }
}

.each_distributor {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #fefeff;
  color: rgb(18, 25, 38);
  padding: 0.8rem;
  border-radius: 0.25rem;
  margin-top: 1rem;
  padding-bottom: 0px;
  @media (max-width: 480px) {
    padding: 10px !important;
    flex-wrap: wrap;
    .margin-left-20 {
      flex-wrap: wrap;
      margin-left: 0px !important;
      margin-top: 20px !important;
    }
  }
  .margin-left-20 {
    margin-left: 20px;
  }
  .place-order-btn-cnt-mb {
    display: flex;
    justify-content: center;
    .place-order-btn-mb {
      background: #14539a;
      width: 150px;
      padding: 0.2rem 0px;
      font-size: 13px;
      display: none;
      color: white;
      position: absolute;
      top: 35px;
      right: -60px;
      height: 28px;
      border-radius: 21px 7px 21px 7px;
      @media (max-width: 480px) {
        display: block;
        position: static;
        margin: 5px 0;
      }
    }
  }
  .place-order-btn {
    background: #14539a;
    width: 100px;
    padding: 0.2rem 0px;
    font-size: 13px;
    color: white;
    position: absolute;
    top: 35px;
    right: -60px;
    height: 28px;
    border-radius: 21px 7px 21px 7px;
    @media (max-width: 480px) {
      display: none;
      position: static;
      margin: 5px 0;
    }
  }
  .seller-profile-img {
    color: #14539a;
    border-radius: 0.5rem;
    object-fit: cover;
    height: 80px;
    width: 80px;
    @media (max-width: 480px) {
      height: 150px;
      width: 200px;
    }
  }
  @media (max-width: 480px) {
    .user_icon {
      align-self: center;
    }
  }
  .user_badage {
    font-size: 80px;
    background: rgb(222, 230, 240);
    color: white;
    border-radius: 50%;
  }
  .cnt_of_date_n_name {
    display: flex;
    @media (max-width: 480px) {
      justify-content: center;
      .distributor_name {
        font-size: 16px;
        margin: 0.5rem 0;
        margin-bottom: -0.5rem;
      }
    }
  }
  .date {
    font-size: 0.89rem;
    color: #606465;
    flex-basis: 100%;
  }
}

.total_item {
  color: #606465;
  font-weight: 480;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

.label[data-shrink='false']
  + .MuiInputBase-formControl
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.75rem 1.25rem;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0.75rem 1.25rem !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 1rem !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #e5e7eb !important;
  border-radius: 0.35rem !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #9fa6b3 !important;
}

@media (max-width: 780px) {
  .order_page_header {
    font-size: 0.9rem;
  }
  .cnt_of_order {
    padding: 0;
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .filterIcon {
    display: flex;
    color: white;
  }
  .cnt_of_brand_distributor {
    display: none;
  }
  .cnt_of_brand_distributor-mobile {
    width: 100vw !important;
    height: 110vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 9;
    background-color: white;
    top: 111px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 22px;
  }
  .cnt_lists_of_distributors {
    padding: 0px !important;
  }
  .order_page_header {
    font-size: 0.75rem !important;
    justify-content: space-between !important;
  }
  .product {
    .product_img {
      height: 150px;
      width: 150px;
    }
    .title {
      font-size: 0.8rem;
    }
    .mrp {
      font-size: 0.75rem;
    }
    .price {
      margin: 5px 0;
      font-size: 14px;
    }
    .order_date,
    .order_id {
      margin: 5px 0;
      font-size: 0.7rem !important;
    }
  }
}

@media (max-width: 300px) {
  .mrp {
    font-size: 8.6px;
  }
  .order_date {
    font-size: 8.5px !important;
  }
}

// allOrder
.all-order-cnt {
  .order {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 15px;
    margin: 1rem 0;
    border-radius: 6px;
    @media (max-width: 480px) {
      padding: 10px;
      position: relative;
    }
    overflow: hidden;
    .urgent-order {
      position: absolute;
      top: 10px; /* Half of the label height */
      right:-18px; /* Half of the label width */
      width: 75px;
      height: 20px;
      background-color: #e00; /* Adjust the background color */
      color: white;
      text-align: center;
      line-height: 20px; /* Centers text vertically */
      transform: rotate(45deg); /* Rotates the label */
    }
  }
}

.header-of-accepting-or-declining {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
}

.order-name-n-img-table-data {
  display: flex;
  align-items: center;
}

.buyer-location,
.buyer-number,
.buyer-order-date,
.buyer-email {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 2px 0;
  font-size: 13px;
  color: #6e6969;
  font-weight: 450;
  @media (max-width: 480px) {
    margin: 4px 0;
    font-size: 14px;
  }
}

.buyer-location-icon,
.buyer-phone-icon,
.buyer-date,
.buyer-email-icon {
  margin-right: 5px;
  color: #14539a;
  margin-top: 2px;
}

.buyer-location {
  margin: 4px 0;
  align-items: start !important;
  @media (max-width: 480px) {
    font-size: 13px !important;
  }
}

.accept-decline-cnt {
  flex-basis: 100%;
  margin-top: 7px;
}

.cross-icon-cnt {
  display: flex;
  justify-content: end;
  .cross-icon {
    display: block;
    color: red;
    font-size: 18px;
  }
}

.cnt-of-seller-img-n-seller-info {
  display: flex;
  flex-basis: 50%;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 480px) {
    .buyer-name-n-location {
      text-align: center;
    }
  }
  .seller-img {
    color: #14539a;
    width: 100px;
    height: 100px;
    border-radius: 13px;
    object-fit: cover;
    margin-right: 16px;
    @media (max-width: 480px) {
      width: 80px;
      height: 80px;
      margin: 0px;
    }
  }
}

.buyer-name {
  text-transform: uppercase;
  font-size: 15.5px;
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 15px;
    margin: 6px 0;
  }
}

.remove-order {
  cursor: pointer;
  color: #14539a;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0.5rem;
  text-align: right;
}

@media (max-width: 480px) {
  .buyer-date-n-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 100%;
  }
}

.see-order-items-cnt {
  display: flex;
  justify-content: center;
  color: #14539a;
  .see-order-items {
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 15px;
  }
}

.bill-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  .order-img {
    object-fit: contain;
    width: 50px;
    height: 50px;
    margin-right: 0.5rem;
  }
  .order-item-name {
    font-size: 0.89rem;
    font-weight: 450;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  tr {
    border-radius: 0.5rem;
  }
  td {
    font-size: 14px;
    padding: 10px;
    text-align: center;
  }
  th {
    color: #6d7785;
    font-size: 13.5px;
    font-weight: 550;
    background-color: #f2f2f2;
    padding: 10px;
    text-align: center;
  }
  .grand-total {
    text-align: left;
    color: rgb(109, 119, 133);
    font-size: 19px;
    font-weight: 550;
  }
}
